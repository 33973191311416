<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white rounded-14">
    <h1 class="package-details-table__title text-font-main text-med-14">
      {{ $t('packages.details_title') }}
    </h1>
    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.user_name') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.name }}</p>
      </b-col>
    </b-row>


    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.email') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.email }}</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.phone') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.phone }}</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.country') }}</p>
      </b-col>
      <b-col sm="6" class="package-details-value">
        <p class="mb-0">{{ detail.country }}</p>
      </b-col>
    </b-row>

    <b-row class="package-details-table__body">
      <b-col sm="6" class="package-details-name">
        <p class="mb-0">{{ $t('table.account_status') }}</p>
      </b-col>
      <div class="package-details-value">
        <b-badge
            pill
            :variant="'light-' + status[1][detail.status]"
            :class="'border-' + status[1][detail.status]"
            class="text-reg-12 py-0 px-3"
        >{{ $t('common.' + status[0][detail.status]) }}</b-badge
        >
      </div>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      status: [
        {
          active: 'active',
          not_active: 'not_active',
        },
        {
          active: 'success',
          not_active: 'input',
        },
      ],
    };
  },

};
</script>