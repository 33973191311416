<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('bookings.title')"
        :sub-title="
        this.$router.currentRoute.name == 'bookings'
          ? $t('bookings.subtitle')
          : ''
      "
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderApplicants"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllBookings"
                :per-page="filterData.per_page"
                :items="getAllBookings"
                :fields="fields"

                :status="bookingStatus"
                :custimized-items="[
                { name: 'fullName' },
                { name: 'action' },
                { name: 'people' },
                { name: 'price' },
                { name: 'start_date' },
                { name: 'created_at' },
              ]"
            >
              <template slot="fullName" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar :src="data.item.image"/>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                      moment(data.item.created_at).format('ll')
                    }}</span>
                </div>
              </template>
              <template slot="start_date" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                      moment(data.item.start_date).format('ll')
                    }}</span>
                </div>
              </template>

              <template slot="people" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                      data.item.people
                    }}</span>
                  {{ $t('packages.tourist') }}
                </div>
              </template>

              <template slot="price" slot-scope="{ data }">
                <div class="d-flex text-main">
                  <span class="text-font-main mx-3 text-main">{{
                      data.item.price
                    }}  {{ $t('common.SR') }}
                  </span>

                </div>
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-14"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1"/>
                  </template>
                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="bookingDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
                v-if="getAllBookings && getAllBookings.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalBookings.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import WTables from '../applicants/WTables.vue';
import WTables from './pages/applicants/WTables.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'year',
          key: 'year',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'month',
          key: 'month',
          id: 'id',
          name: 'name',
          translate: true,
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'fullName',
          label: this.$i18n.t('bookings.package_name'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('bookings.booking_time'),
          sortable: true,
        },
        {
          key: 'start_date',
          label: this.$i18n.t('bookings.start_date'),
          sortable: true,
        },
        {
          key: 'people',
          label: this.$i18n.t('bookings.individual_count'),
          sortable: true,
        },
        {
          key: 'price',
          label: this.$i18n.t('bookings.price'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('bookings.status'),
          sortable: true,
        },

        {key: 'action', label: ''},
      ],
      bookingStatus: [
        {
          active: 'active',
          not_active: 'not_active',
          soon: 'soon',
          expired: 'expired',
          not_available: 'not_available',
        },
        {
          active: 'success',
          soon: 'warning',
          not_active: 'danger',
          expired: 'danger',
          not_available: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllBookings: 'getAllBookings',
      getTotalBookings: 'getTotalBookings',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[1].data = newVal.months ? newVal.months : [];
        this.content[0].data = newVal.years ? newVal.years : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadInnerFilterData({ data: 'bookings', type: 'user', id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      loadInnerFilterData: 'loadInnerFilterData',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.$store.dispatch('loadTouristBookings', {
        data: this.filterData,
        id: this.$route.params.id,
      });
      // this.loadBookings(this.filterData);
    },
    bookingDetails(id) {
      this.$router.push({
        name: 'booking-details',
        params: {lang: this.$i18n.locale, id},
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};

</script>
