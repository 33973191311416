<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
          :title="$t('users.tourists.detail.title')"
          :subTitle="$t('users.tourists.detail.desc')"
      />
    </div>
    <section class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col md="12" class="my-4">
            <tourist-details-table :detail="getUserDetail" />
          </b-col>
        </b-row>
      </div>
      <fetch-tourist-bookings></fetch-tourist-bookings>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import TouristDetailsTable from '@/components/pages/applicants/TouristDetailsTable.vue';
import FetchTouristBookings from '@/components/fetchTouristBookings.vue';

export default {
  components: {
    FetchTouristBookings,
    TouristDetailsTable,
    PageHeader,
  },

  computed: {
    ...mapGetters({
      getUserDetail: 'getUserDetail',
    }),
  },
  mounted() {
    this.loadDetail(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadDetail: 'loadTouristDetail',
    }),

  },
};
</script>
